@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;
@use '@styles/mixins' as mxs;

.boxWithoutMarginWrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 48vw;

  @include breakpoints.down(md) {
    height: 100%;
    flex-direction: column;
    gap: 0;
    padding: 0;
  }

  &.reverse {
    flex-direction: row-reverse;
    gap: functions.spacing(16);

    @include breakpoints.down(md) {
      flex-direction: column;
      gap: 0;
    }
  }
}

.boxWithoutMarginMediaWrapper {
  aspect-ratio: 1/1;
  flex-basis: 50%;

  @include breakpoints.down(md) {
    max-width: none;
    width: 100%;
    margin: 0;
  }

  :global(.media-wrapper) {
    height: 100%;
    width: 100%;

    > a {
      display: block;
      height: 100%;
    }
  }

  .hideTextModule {
    display: none;
  }
  video {
    min-height: 375px;
  }
}

.boxWithMarginWrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 40vw;
  margin: 3rem;
  padding: 16px;

  @include breakpoints.up(lg) {
    padding: 0;
  }

  @include breakpoints.down(md) {
    height: 100%;
    flex-direction: column;
    gap: 0;
    margin: 0;
  }

  &.reverse {
    flex-direction: row-reverse;
    gap: functions.spacing(16);

    @include breakpoints.down(md) {
      flex-direction: column;
      gap: 0;
    }
  }
}

.boxWithMarginMediaWrapper {
  aspect-ratio: 1/1;
  flex-basis: 50%;

  @include breakpoints.down(md) {
    max-width: none;
    width: 90vw;
    height: 90vw;
    margin: auto;
  }

  .media-wrapper {
    height: 100%;
    width: 100%;

    > a {
      display: block;
      height: 100%;
    }
  }
  .hideTextModule {
    display: none;
  }

  video {
    min-height: 375px;
  }
}

.boxWithMarginVideoWrapper {
  height: auto;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;

  :global(.video-player) {
    height: 100%;
  }
}

.boxWithMarginTextWrapper {
  align-items: flex-start;
  flex-basis: calc(50% - 14rem);
  justify-content: center;
  min-width: auto;
  padding: 0;
  padding-block: 1rem;

  h2 {
    font-size: 20px;

    @include breakpoints.up(md) {
      font-size: 24px;
    }
  }

  @include breakpoints.down(lg) {
    flex-basis: calc(50% - 6rem);
  }

  @include breakpoints.down(md) {
    align-items: center;
    margin: functions.spacing(6, 0);
    order: 2;
    padding: 1rem;

    // override alignment setting on mobile
    &:global(.left),
    &:global(.right) {
      align-items: center;

      :global(.cta-container) {
        align-items: center;
      }
    }
  }

  @include breakpoints.up(md) {
    &:global(.right) {
      align-items: flex-end;

      :global(.teaser-inner-wrapper) {
        align-items: flex-end;
        text-align: right;
      }

      :global(.cta-container) {
        align-items: flex-end;
      }
    }
  }

  :global(.teaser-inner-wrapper) {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    text-align: left;

    @include breakpoints.down(md) {
      align-items: center;
      text-align: center;
    }

    h2 {
      line-height: 1.25;
    }

    svg {
      width: 136px;
    }
  }

  :global(.cta-container) {
    align-items: flex-start;
    flex-direction: column;

    @include breakpoints.down(md) {
      align-items: center;

      a,
      button {
        max-width: unset;
      }
    }
  }

  &:global(.center) {
    align-items: center;
    margin: auto;

    :global(.teaser-inner-wrapper) {
      align-items: center;
      text-align: center;
    }

    :global(.cta-container) {
      align-items: center;
    }
  }

  a,
  button {
    font-weight: normal;
    max-width: 287px;
    width: 100%;
  }
}

.termsAndConditions {
  margin-top: 0.75rem;
  position: unset;
  width: auto;

  button {
    font-size: 1rem;
    text-decoration: underline;

    &:hover {
      color: black;
    }
  }
}
