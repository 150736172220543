@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;
@use '@styles/mixins' as mxs;

.squatBanner {
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 15rem;

  @include breakpoints.down(480px) {
    min-height: 23.438rem;
  }

  @include breakpoints.down(lg) {

    &:global(.has-terms) {
      padding-bottom: 3.5rem;
    }
  }

  [class*="cmsMediaLink"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  video {
    height: 100%;
  }

  :global(.block-left-middle) {
    margin-left: auto;
    margin-right: auto;

    @include breakpoints.up(lg) {
      margin-left: 5rem;
    }
  }

  :global(.block-center-middle) {
    margin-left: auto;
    margin-right: auto;
  }

  :global(.block-right-middle) {
    margin-left: auto;
    margin-right: auto;

    @include breakpoints.up(lg) {
      margin-right: 5rem;
    }
  }

  :global(.text-module) {
    padding: 0;
    position: relative;
    width: 100%;
    gap: 0;

    @include breakpoints.up(lg) {
      max-width: 40.625rem;
      width: auto;
    }
  }
}

.squatBannerVideoWrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.squatBannerTermsConditions {
  position: absolute;
  display: flex;
  width: 100%;
  z-index: 1;
  bottom: 0;

  @include breakpoints.up(md) {
    padding-right: functions.spacing(6);
  }

  button {
    margin-bottom: functions.spacing(4.5);
  }

  &> :global(.toast) button {
    right: functions.spacing(6);
  }
}